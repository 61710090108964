import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
  lists: [],
  uploadProgress: {},
  recordingStatus: "",
  pausedRecording: {},
  newRecording: {},
  conversationList: [],
};

export const freedSlice = createSlice({
  name: "freed",
  initialState,
  reducers: {
    handleAddListItemAction: (state, action) => {
        return (state = {
          ...state,
          lists: [...state.lists, action.payload],
        });
    },
    handleRemoveListItemAction: (state, action) => {
      return (state = {
        ...state,
        lists: state.lists.filter?.(
          (listItem) => listItem?.id !== action.payload
        ),
      });
    },
    handleUploadItemProgressAction: (state, action) => {
      return (state = {
        ...state,
        uploadProgress: {
          ...state.uploadProgress,
          [action.payload?.id]: action.payload.progress,
        },
      });
    },
    handleRecordingStatusAction: (state, action) => {
      return (state = {
        ...state,
        recordingStatus: action.payload,
      });
    },
    handlePausedRecording: (state, action) => {
      return (state = {
        ...state,
        pausedRecording: action.payload,
      });
    },
    handleNewRecording: (state, action) => {
      return (state = {
        ...state,
        newRecording: action.payload,
      });
    },
    handleAddConversationListAction: (state, action) => {
      return (state = {
        ...state,
        conversationList: action.payload,
      });
    },
    handleRemoveItemFromConversationList: (state, action) => {
      return (state = {
        conversationList: action.payload,
      });
    },
    handleAddItemInConversationListAction: (state, action) => {
      return (state = {
        ...state,
        conversationList: state?.conversationList?.push(action.payload),
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  handleAddListItemAction,
  handleUploadItemProgressAction,
  handleRecordingStatusAction,
  handlePausedRecording,
  handleNewRecording,
  handleAddConversationListAction,
  handleRemoveItemFromConversationList,
  handleAddItemInConversationListAction,
} = freedSlice.actions;

export const handleAddListItem = (data) => async (dispatch) => {
  dispatch(handleAddListItemAction(data));
};

export default freedSlice.reducer;
