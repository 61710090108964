export const APP_NAME = process.env.REACT_APP_APP_NAME || "Freed";

export const APP_KEY = process.env.REACT_APP_APP_KEY || "freed";

export const CUSTOMER_APP_KEY = process.env.REACT_APP_CUSTOMER_KEY || "tryndx";

export const ENVIRONMENT = process.env.REACT_APP_ENV || "development";

export const LANGUAGE = process.env.REACT_APP_LANG || "en";

export const USER_LOGIN_CREDENTIAL_KEY =
  process.env.REACT_APP_LOGIN_CREDENTIALS || "AdMiN_LoGiN_CrEdEnTiAl";
