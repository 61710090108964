import React from "react";
import { Outlet } from "react-router";

import Header from "components/Header";

const PublicLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default PublicLayout;
