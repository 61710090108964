import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { authSlice, freedSlice } from "../redux";
import { APP_KEY, ENVIRONMENT } from "config/app.config";
import logger from "helpers/logger";

const RootReducer = combineReducers({
  auth: authSlice,
  freed: freedSlice,
});

const encryptor = encryptTransform({
  secretKey: `${APP_KEY}-storage`,
  onError: (error) => {
    logger({ error });
  },
});

const persistConfig = {
  key: APP_KEY,
  storage,
  whitelist: ["auth", "freed"],
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    // https://github.com/rt2zz/redux-persist/issues/988#issuecomment-552242978
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  devTools: ENVIRONMENT !== "production",
});

export default store;

export const Persistor = persistStore(store);
