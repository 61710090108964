import { ENVIRONMENT } from "config/app.config";

const logger = (...arg) => {
  if (ENVIRONMENT !== "production") {
    // eslint-disable-next-line
    console.log("logger", arg);
  }
  return false;
};

export default logger;
