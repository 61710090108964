import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import clsx from "clsx";

import useResponsive from "utils/useResponsive";
import { commonService } from "services/common.service";
import { FREED_ENDPOINTS } from "apiEndpoints/Freed";

import {
  Add,
  FormatListBulleted,
  MailOutline,
  AutoDelete,
  FolderDelete,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import CheckboxList from "components/CheckboxList";
import { useTypedSelector } from "utils/useTypeSelector";
import { useDispatch } from "react-redux";
import {
  handleAddConversationListAction,
  handlePausedRecording,
  handleRecordingStatusAction,
  handleRemoveItemFromConversationList,
} from "redux/FreedSlize/index.slice";

const options = [
  { title: "All Notes", icon: <FormatListBulleted /> },
  { title: "Unread Notes", icon: <MailOutline /> },
  { title: "Deleted Soon", icon: <AutoDelete /> },
  { title: "Trash", icon: <FolderDelete /> },
];

const Sidebar = () => {
  const { sm } = useResponsive();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { recordingStatus, pausedRecording, newRecording, conversationList } =
    useTypedSelector((state) => state.freed);
  const pathIsExpired = pathname.includes("expired-subscriber");
  const dispatch = useDispatch();
  const [state, setState] = useState({
    anchorEl: null,
    selectedIndex: 1,
    lists: [],
    isLoading: false,
    deleteItems: [],
  });

  const open = Boolean(state.anchorEl);

  const handleStateChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      ...(typeof key === "string" ? { [key]: value } : key),
    }));
  };

  const handleClickListItem = (event) => {
    handleStateChange("anchorEl", event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    handleStateChange({
      anchorEl: null,
      selectedIndex: index,
    });
  };

  const handleClose = () => {
    handleStateChange("anchorEl", null);
  };

  const handleNavigate = () => {
    navigate("/record");
  };

  const handleDelete = async (id) => {
    const response = await commonService({
      apiEndPoint: FREED_ENDPOINTS?.deleteConversation,
      bodyData: id ? [id] : state?.deleteItems,
      isFormData: true,
    });

    if (response?.status) {
      let arr = conversationList.filter((list) => {
        if (id) {
          return list?._id !== id;
        } else {
          return !state?.deleteItems?.includes(list?._id);
        }
      });
      dispatch(handleRemoveItemFromConversationList(arr));
    }
  };
  const getConversationList = async () => {
    try {
      handleStateChange("isLoading", true);
      const response = await commonService({
        apiEndPoint: FREED_ENDPOINTS?.getConversationLists,
        isToast: false,
      });
      let arr = [];
      arr = response?.data?.filter((item) => {
        if (!item?.end_visit) dispatch(handlePausedRecording(item));
        if (item?._id === pausedRecording?._id && item?.end_visit)
          dispatch(handlePausedRecording({}));
        return item?.end_visit;
      });

      dispatch(handleAddConversationListAction(arr));
      handleStateChange({
        // lists: ,
        isLoading: false,
      });
    } catch (error) {
      handleStateChange("isLoading", false);
    }
  };

  useEffect(() => {
    if (newRecording?.name) {
      dispatch(handleRecordingStatusAction(""));
      dispatch(handlePausedRecording({}));
    }
  }, [newRecording]);

  useEffect(() => {
    getConversationList();
  }, []);

  return (
    <>
      {sm && (
        <aside
          className={clsx(
            "relative w-full",
            "w-0 sm:w-[70px] xl:w-[300px]",
            pathIsExpired && "z-[110]"
          )}
        >
          <div
            className={clsx(
              "dark:bg-white/[0.06] bg-white",
              "w-full h-full fixed pt-4 px-4",
              "flex flex-col justify-between",
              "border-r",
              "dark:border-r-divider-500 border-r-divider-light-400",
              "max-w-[280px] xl:max-w-[300px]",
              "transition-[max-width] duration-500"
            )}
          >
            <div>
              <button
                onClick={handleNavigate}
                className="flex items-center space-x-1 justify-center border w-full py-1 rounded-md"
              >
                <Add fontSize="small" />
                <span>Start a Visit</span>
              </button>
              <div className="flex items-center border-b">
                <Checkbox defaultChecked focusRipple={false} disableRipple />
                {state.deleteItems.length ? (
                  <>
                    <ListItem
                      button
                      id="lock-button"
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-label="when device is locked"
                      aria-expanded={open ? "true" : undefined}
                      className="border"
                      sx={{ color: "red" }}
                      onClick={() => handleDelete()}
                    >
                      <ListItemText primary={"DELETE SELECTED"} />
                    </ListItem>
                  </>
                ) : (
                  <List
                    // component="nav"
                    className="w-full"
                  >
                    <ListItem
                      button
                      id="lock-button"
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-label="when device is locked"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClickListItem}
                      className="border"
                    >
                      <ListItemText
                        primary={options[state.selectedIndex].title}
                      />
                    </ListItem>
                  </List>
                )}
              </div>
              <Menu
                id="lock-menu"
                anchorEl={state.anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "lock-button",
                  role: "listbox",
                }}
                className="w-full"
              >
                {options.map((option, index) => (
                  <MenuItem
                    key={option.title}
                    selected={index === state.selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    <div className="flex items-center w-48 space-x-2">
                      {option.icon}
                      <span>{option.title}</span>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
              {newRecording?.status && (
                <ListItem disabled>
                  <ListItemText
                    primary={newRecording?.name}
                    secondary={newRecording?.date}
                    className="ms-14 cursor-pointer"
                  />
                  <Box sx={{ display: "flex", padding: "5px" }}>
                    <CircularProgress size={25} />
                  </Box>
                </ListItem>
              )}
              {conversationList?.length > 0 && !state.isLoading ? (
                <CheckboxList
                  state={state}
                  conversationList={conversationList}
                  recordStatus={recordingStatus}
                  handleDelete={handleDelete}
                  pausedRecording={pausedRecording}
                  handleStateChange={handleStateChange}
                />
              ) : state?.isLoading && !state?.lists?.length ? (
                <div className="flex items-center justify-center m-auto pt-4">
                  <CircularProgress size={30} />
                </div>
              ) : null}
            </div>
          </div>
        </aside>
      )}
    </>
  );
};

export default Sidebar;
