import React, { lazy } from "react";
import { Navigate } from "react-router";

import Loadable from "components/Loadable";
import PublicLayout from "layout/PublicLayout";

const SignUp = Loadable(lazy(() => import("pages/Auth/SignUp")));
const Login = Loadable(lazy(() => import("pages/Auth/Login")));
const ResetPassword = Loadable(lazy(() => import("pages/Auth/ResetPassword")));

const routes = [
  {
    element: <PublicLayout />,
    children: [
      {
        path: "/auth/sign-up",
        element: <SignUp />,
      },
      {
        path: "/auth/login",
        element: <Login />,
      },
      {
        path: "/auth/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/auth/verify-email/success",
        // element: <UserVerifySucces />,
      },
      {
        path: "/auth/verify-email/failed",
        // element: <UserVerifyFailed />,
      },
    ],
  },
  { path: "/auth", element: <Navigate to="/auth/login" replace /> },
  { path: "*", element: <Navigate to="/auth/login" replace /> },
];

export default routes;
