import React from "react";
import clsx from "clsx";
import { Outlet, useLocation, useNavigate } from "react-router";

import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import Header from "components/Header";
import Sidebar from "components/Sidebar";

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = () => {
    navigate("/record");
  };
  return (
    <div className="fixed w-full grid bg-gray-100">
      <Header />
      <div
        className={clsx(
          "w-full grid bg-gray-100 z-40 dark:bg-black",
          "grid-cols-1 sm:grid-cols-[min-content_minmax(0%,100%)] relative",
        )}
      >
        <Sidebar />
        <div className="w-full max-h-max h-screen ">
          <Outlet />
          {location.pathname !== "/record" && (
            <IconButton
              color="default"
              size="large"
              className="absolute right-10 top-[-50px] float-right z-50"
              sx={{
                backgroundColor: "dodgerblue",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                ":hover": {
                  backgroundColor: "#006ACA",
                },
              }}
              onClick={handleNavigate}
            >
              <AddIcon sx={{ color: "white" }} />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;
