import { API_ENDPOINT_V1 } from 'config/api.config'

const API_ENDPOINT = API_ENDPOINT_V1

export const FREED_ENDPOINTS = {
  getConversationLists: {
    url: `${API_ENDPOINT}/conversation/get_conversation`,
    method: 'GET'
  },
  putConversationCategory: {
    url: `${API_ENDPOINT}/conversation/update_conversation_categories`,
    method: 'PUT'
  },
  postCaptureConversation: {
    url: `${API_ENDPOINT}/conversation/capture_conversation`,
    method: 'POST'
  },
  postPauseCaptureConversation: {
    url: `${API_ENDPOINT}/conversation/paused_capture_conversation`,
    method: 'POST'
  },
  getSingleConversationById: (id) => ({
    url: `${API_ENDPOINT}/conversation/get_single_conv_by_coversation_id?obj_id=${id}`,
    method: 'GET'
  }),
  deleteConversation: {
    url: `${API_ENDPOINT}/conversation/delete_conversation`,
    method: 'DELETE'
  }
}
