import React, { lazy } from "react";
import { Navigate } from "react-router";

import Layout from "layout";
import Loadable from "components/Loadable";

const Home = Loadable(lazy(() => import("pages/Home")));
const Record = Loadable(lazy(() => import("pages/Record")));
const You = Loadable(lazy(() => import("pages/You")));
const AccountSettings = Loadable(lazy(() => import("pages/AccountSettings")));
const MicHelp = Loadable(lazy(() => import("pages/MicHelp")));
const VisitConversation = Loadable(lazy(() => import("pages/Visit")));

const routes = [
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/record",
        element: <Record />,
      },
      {
        path: "/you",
        element: <You />,
      },
      {
        path: "/account-settings",
        element: <AccountSettings />,
      },
      {
        path: "/help/windows-chrome-mic",
        element: <MicHelp />,
      },
      {
        path: "/visit/:visitId",
        element: <VisitConversation />,
      },
    ],
  },
  { path: "*", element: <Navigate to="/" replace /> },
];

export default routes;
