import React, { Suspense } from "react";

import Loader from "../Loader";

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable =
  // eslint-disable-next-line react/display-name, no-undef
  (child) => (props) => {
    const Component = child;
    return (
      <Suspense
        fallback={
          <div className="flex justify-center items-center text-center min-h-screen">
            <Loader />
          </div>
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };

export default Loadable;
