import "./index.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import moment from "moment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { KeyboardVoice, Pause } from "@mui/icons-material";
import { useTypedSelector } from "utils/useTypeSelector";
import { formattedDateTime } from "helpers";

const CheckboxList = (props) => {
  const {
    state,
    recordStatus,
    handleDelete,
    handleStateChange,
    pausedRecording,
  } = props;

  const { conversationList } = useTypedSelector((state) => state?.freed);
  const navigate = useNavigate();
  const [checked, setChecked] = useState([null]);
  const [array, setArray] = useState([]);
  const handleToggle = (value) => () => {
    handleStateChange(
      "deleteItems",
      state?.deleteItems?.includes(conversationList[value]?._id)
        ? state?.deleteItems.filter(
            (item) => item !== conversationList[value]?._id
          )
        : [...state?.deleteItems, conversationList[value]._id]
    );
    const currentIndex = checked?.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleSelectItem = (item) => {
    navigate(`/visit/${item._id}`, { replace: true });
  };

  useEffect(() => {
    let arr = [...conversationList];
    arr?.sort((a, b) => b?.Created_at - a?.Created_at);
    setArray(arr);
  }, [conversationList]);

  return (
    <List
      className="max-h-[700px] overflow-y-auto overflow-x-hidden"
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
    >
      {(recordStatus || pausedRecording?._id) && (
        <ListItem
          key={-1}
          className={`ms-2`}
          onClick={() => navigate("/record")}
          secondaryAction={
            <IconButton edge="end" aria-label="comments">
              {recordStatus === "Recording" ? <KeyboardVoice /> : <Pause />}
            </IconButton>
          }
          disablePadding
        >
          <ListItemButton role={undefined} dense>
            <ListItemText
              id={-1}
              primary={recordStatus || "Paused"}
              secondary={
                recordStatus
                  ? formattedDateTime
                  : moment
                      .unix(pausedRecording.Created_at)
                      .format("DD-MM-YYYY hh:mm:ss A")
              }
            />
          </ListItemButton>
        </ListItem>
      )}
      {array?.map((value, idx) => {
        const labelId = `checkbox-list-label-${idx}`;
        return (
          <div key={idx} className="list-item">
            <ListItem
              key={idx}
              className={`${!checked?.includes(idx) && "hover-list-item"}`}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="comments"
                  onClick={() => handleDelete(value._id)}
                >
                  <DeleteIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton role={undefined} dense>
                <ListItemIcon className="p-0 m-0">
                  <Checkbox
                    className="cardcheckbox p-0 m-0 "
                    edge="start"
                    checked={checked.indexOf(idx) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                    onClick={handleToggle(idx, value?._id)}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{ width: "150px", margin: "0px" }}
                  id={labelId}
                  onClick={() => handleSelectItem(value)}
                  primary={value?.patient_name}
                  secondary={moment
                    .unix(value?.Created_at)
                    .format("DD/MM/YYYY hh:mm a")}
                />
              </ListItemButton>
            </ListItem>
          </div>
        );
      })}
    </List>
  );
};

export default CheckboxList;
