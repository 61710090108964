import React from "react";

import Routes from "routes";
import ScrollTop from "components/ScrollTop";

function App() {
  return (
    <ScrollTop>
      <Routes />
    </ScrollTop>
  );
}

export default App;
