import { useRoutes } from "react-router-dom";
import { connect } from "react-redux";

import UserRoutes from "./UserRoutes";
import PublicRoutes from "./PublicRoutes";

function ThemeRoutes(props) {
  const { isLogin } = props;

  const getRoutes = () => {
    if (isLogin) {
      return UserRoutes;
    } else {
      return PublicRoutes;
    }
  };

  return useRoutes([...getRoutes()]);
}

const mapStateToProps = (state) => ({
  isLogin: state?.auth?.isLogin,
});

export default connect(mapStateToProps)(ThemeRoutes);
