export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
export const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const eraseAllCookie = () => {
  const Cookies = document.cookie.split(";");

  for (let i = 0; i < Cookies.length; i++) {
    document.cookie = Cookies[i] + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
};

export const capitalizeFirstLetter = (string) => {
  return (
    typeof string === "string" &&
    string?.charAt(0).toUpperCase() + string.slice(1)
  );
};

export const getFullName = (first, middle, last) => {
  try {
    let name = capitalizeFirstLetter(first);
    if (!name) {
      return "";
    }
    if (middle) {
      name += ` ${capitalizeFirstLetter(middle)}`;
    }
    if (last) {
      name += ` ${capitalizeFirstLetter(last)}`;
    }
    return name;
  } catch (error) {
    return "";
  }
};

export const generateUniqueId = () => {
  const timestamp = Date.now().toString(16);
  const randomPart = Math.random().toString(16).substr(2, 10);
  const paddingLength = 24 - timestamp.length - randomPart.length;
  const padding = "0".repeat(Math.max(0, paddingLength));
  const uniqueId = timestamp + padding + randomPart;
  return uniqueId;
};

export const formattedDateTime = new Date().toLocaleString("en-GB", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: true,
});
