import { createSlice } from "@reduxjs/toolkit";
import logger from "helpers/logger";
import { PURGE } from "redux-persist";

const initialState = {
  isLogin: false,
  user: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginAction: (state, action) => {
      return (state = {
        ...state,
        isLogin: true,
        user: action.payload,
      });
    },
    logoutAction: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { loginAction, logoutAction } = authSlice.actions;

export const login = (data) => async (dispatch) => {
  try {
    dispatch(loginAction(data));
  } catch (error) {
    logger(error);
  }
};

export const logout = (data) => async (dispatch) => {
  try {
    dispatch(logoutAction(data));
    localStorage.clear();
  } catch (error) {
    logger(error);
  }
};

export default authSlice.reducer;
