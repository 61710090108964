import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { IconButton, Menu, MenuItem } from '@mui/material'
import {
  ChatBubbleOutline,
  AccountCircle,
  Person,
  ManageAccounts,
  ShoppingCart,
  FavoriteBorder,
  Logout
} from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'redux/AuthSlice/index.slice'
import { Persistor } from 'store'

const menuOptions = [
  {
    icon: Person,
    title: 'You',
    path: '/you'
  },
  {
    icon: ManageAccounts,
    title: 'Account Settings',
    path: '/account-settings'
  },
  {
    icon: ShoppingCart,
    title: 'Subscribe'
  },
  {
    icon: FavoriteBorder,
    title: 'Share Freed'
  },
  {
    icon: Logout,
    title: 'Logout'
  }
]

const Header = () => {
  const authState = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickMenuItem = (item) => {
    if (item.path) {
      navigate(item.path)
    }
    if (item.title === 'Logout') {
      dispatch(logout())
      navigate('/auth/sign-in')
      Persistor.purge()
    }
    handleClose()
  }

  return (
    <div className="flex items-center justify-between w-full py-3 px-8 border-b border bg-white sticky top-0 z-50">
      <div>
        <Link to="/" className="text-2xl">
          SOAP
        </Link>
      </div>
      {authState?.isLogin && (
        <div>
          <IconButton>
            <ChatBubbleOutline color="#fff" />
          </IconButton>
          <IconButton onClick={handleClick}>
            <AccountCircle />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}>
            {menuOptions.map((item, idx) => (
              <MenuItem
                key={idx}
                className="flex items-center space-x-2 px-3"
                onClick={() => handleClickMenuItem(item)}>
                <item.icon />
                <span>{item.title}</span>
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </div>
  )
}

export default Header
